export default {
  type: 'contact_update',
  engagement: true,
  icon: 'contactUpdate',
  title: 'Update Learner Profile - Single Field',
  help: 'Allow the learner to update a field in their contact profile.',
  schema: {
    question: {
      type: 'TextArea',
      title: 'Question / Field Label',
      help: 'Preface your information request with a question or label.',
      default: '',
    },
    contact_field: {
      type: 'ContactFieldList',
      title: 'Contact Field',
      help: 'Select the contact field you want to make updatable',
      validators: ['editable'],
      allow_types: ['related_custom_object'],
      // default : []
    },
    hide_value: {
      type: 'Checkbox',
      title: 'Hide existing value from contact',
      default: false,
      help: 'Helpful if the information is sensitive or you want a fresh answer.',
    },
    skip_already_set: {
      type: 'Checkbox',
      title: 'Skip if already set',
      default: false,
      help: "Don't require a submission if there is already a value saved.",
    },
    // this was initiall a requirement, but backend doesn't support skipping right now.
    // required: {
    //   type: 'Checkbox',
    //   title: 'Must Fill',
    //   default: false,
    //   help: '',
    // },
  },
};
