<template>
  <field-block title="New Content" v-if="feed.length">
    <div
      class="link-card item-card d-flex mb-3"
      v-for="(link, idx) in feed"
      :key="idx"
      @click="openLink(link.link)"
    >
      <div class="item-card__side-image" v-if="link.enclosures">
        <v-responsive :aspect-ratio="16 / 9">
          <img v-lazy="getImage(link.enclosures)" alt="" />
        </v-responsive>
      </div>
      <div class="item-card__description">
        <content-line :text="link.title" class="pr-1" />
        <div class="item-card__content">
          {{ link.description }}
        </div>
      </div>
    </div>
  </field-block>
</template>

<script>
import { parse } from 'rss-to-json';

export default {
  name: 'HomeUpdates',
  data() {
    return {
      links: [
        {
          link: '#',
          text: 'Title',
        },
        {
          link: '#',
          text: 'Title',
        },
        {
          link: '#',
          text: 'Title',
        },
      ],
      feed: [],
    };
  },
  methods: {
    async checkRSSFeed() {
      const CYFEED = 'https://conveyour.com/blog/rss.xml';
      const rss = await parse(CYFEED);
      if (rss?.items) {
        this.feed = (rss?.items || []).slice(0, 3);
      }
    },
    openLink(url) {
      window.open(url, '_blank');
    },
    getImage(arr) {
      if (!Array.isArray(arr) || !arr.length) return;
      return arr[0].url || '';
    },
  },
  created() {
    this.checkRSSFeed();
  },
};
</script>

<style lang="less">
.item-card.link-card {
  cursor: pointer !important;

  .item-card__side-image {
    width: 42%;
    display: flex;
    align-items: center;
  }

  &:hover {
    background-color: var(--v-borderColor-base) !important;
    .item-card__description {
      background-color: var(--v-darkGray-base);
    }
  }

  .item-card__content {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 76px;
    margin-bottom: 4px;
  }
}

@media (max-width: 600px) {
  .item-card.link-card {
    flex-direction: column;

    .item-card__side-image {
      width: 100%;
      flex: 1;

      .v-responsive {
        max-width: 420px;
        margin: 0 auto;
      }
    }

    .item-card__description {
      max-width: 100%;
    }

    & + .item-card {
      margin-top: 24px;
    }
  }
}
</style>
